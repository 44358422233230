import { Link, Outlet } from 'react-router-dom';
import background from '@/assets/bg1.webp';
import logo from '@/assets/logo.png';
import { ScrollArea } from '@packages/ui';

export const Layout = () => {
  return (
    <div
      className="h-screen w-screen flex flex-col overflow-hidden relative"
      style={{
        background: `url(${background})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}>
      <div className="h-screen w-screen bg-background absolute opacity-60"></div>
      <main className="grow min-h-0 container flex justify-center mt-12 mb-12 overflow-hidden">
        <div className="flex flex-col max-w-[600px]">
          <ScrollArea className="border border-white rounded-xl text-white backdrop-blur px-4 pb-4">
            <img src={logo} className="w-[400px] ml-auto mr-auto p-12" />
            <Outlet />
          </ScrollArea>
        </div>
      </main>
      <footer className="border-t py-2 backdrop-blur bg-white/20 text-green-100 font-bold">
        <div className="container space-x-8 text-center text-xs">
          <Link to="/">Tickets</Link>
          <Link to="/imprint">Imprint</Link>
          <Link to="/privacy">Privacy</Link>
        </div>
      </footer>
    </div>
  );
};
