import { FirebaseClient } from '@/api/firebaseClient';
import { QUERY_KEYS, ticketTypeQuery } from '@/api/queries';
import { useInvalidatingMutation } from '@/hooks/useInvalidatingMutation';
import { BuyTicketRequest, TicketType } from '@packages/types';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Input,
  Button,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@packages/ui';

export const TicketsPage = () => {
  const navigate = useNavigate();

  const client = new FirebaseClient();

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [ticketType, setTicketType] = useState('');

  const { data: ticketTypes, isLoading } = useQuery(ticketTypeQuery());
  const { mutateAsync: buyTicket } = useInvalidatingMutation([QUERY_KEYS.TICKETS], {
    mutationFn: async (data: BuyTicketRequest) => client.buyTicket(data),
  });

  const availableTicketTypes = useMemo(() => {
    return (ticketTypes?.filter((t) => t.available > 0) ?? []).sort((a, b) => a.price - b.price);
  }, [ticketTypes]);

  const selectedTicketType = useMemo<TicketType>(() => {
    const type = availableTicketTypes.find((t) => t.name === ticketType);
    return type ?? availableTicketTypes[0];
  }, [availableTicketTypes, ticketType]);

  useEffect(() => {
    if (!ticketType && availableTicketTypes.length) {
      setTicketType(availableTicketTypes[0].name);
    }
  }, [availableTicketTypes, ticketType]);

  const onSubmit = async () => {
    setError('');
    const form: HTMLFormElement | null = document.getElementById(
      'ticketForm',
    ) as HTMLFormElement | null;
    if (!form) return;
    const isValid = form.reportValidity();
    if (!isValid) return;

    if (!email) {
      setError('Please enter an email.');
      return;
    }
    if (!name) {
      setError('Please enter your name.');
      return;
    }
    try {
      const result = await buyTicket({ ticketType, name, email });
      navigate('/tickets/success', { state: result.data });
    } catch (e) {
      console.error('Error buying ticket', e);
      setError('Ticket could not be bought.');
    }
  };

  if (isLoading) {
    return null;
  }

  if (!availableTicketTypes.length) {
    return <div>There are no tickets available.</div>;
  }

  return (
    <div className="flex flex-col items-center">
      <h1 className="text-xl font-medium pb-2">Buy your ticket now</h1>

      <form id="ticketForm" className="flex flex-col w-full gap-2 p-4 max-w-[400px] max-w-screen">
        <Select
          value={ticketType}
          onValueChange={(value: string) => {
            setTicketType(value);
          }}>
          <SelectTrigger className="w-full bg-white/10">
            <SelectValue placeholder={selectedTicketType.label} />
          </SelectTrigger>
          <SelectContent className="">
            {availableTicketTypes.map((type) => (
              <SelectItem key={type.name} value={type.name}>
                {type.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        {ticketType ? (
          <>
            <Input
              className="p-2 border rounded w-full"
              type="email"
              required
              placeholder="Email"
              value={email}
              onChange={(input) => setEmail(input.target.value)}
            />

            <Input
              className="p-2 border rounded w-full"
              name="name"
              required
              type="text"
              value={name}
              onChange={(input) => setName(input.target.value)}
              placeholder="First and last name"
            />

            <div className="cart mt-8 mb-4 flex flex-col text-lg">
              <span>Total price</span>
              <span className="font-bold text-3xl">{selectedTicketType.price} EUR</span>
            </div>

            <Button onClick={() => onSubmit()} className="px-4 py-4 rounded text-white">
              Buy Ticket
            </Button>
          </>
        ) : null}

        {error && <div className="text-red-500">{error}</div>}
      </form>
    </div>
  );
};
